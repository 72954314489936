.App {
  width: 100vw;
  height: 100vh;
}

pre.json {
  font-size: 10px;
  max-width: 50vw;
  max-height: 50vh;
  overflow: scroll;
}

.icon {
  background: unset;
  border: unset;
}